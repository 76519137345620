import { useAppDispatch } from '@hooks/useAppDispatch';
import axios from 'axios';
import { getCartStatus } from '@api/interfaces/cartApi';
import { setConflictingModalStoreId } from '@slices/conflictingModalSlice';
import showCartConflictingModal from '@helpers/showCartConflictingModal';
import { getOrderStatus } from '@api/interfaces/orderApi';
import { getListStatus, getWishListEntriesStatus } from '@api/interfaces/wishlistApi';
import { initCheckout } from '@api/interfaces/checkoutApi';
import { WishListFormEntry } from '@helpers/wishlistEntriesToForm';
import { AxfoodCartEntryStatusViewModel } from '@api/generated/storefront';
import useCustomer from '@hooks/useCustomer';

/**
 * Iterate over the returned items to see which products are conflicting in the cart
 */
const getCartConflictingItems = (isB2B: boolean, data?: Array<AxfoodCartEntryStatusViewModel>) => {
  return data?.filter((item) => {
    return (
      item.priceAbscent ||
      item.outOfStock ||
      item.partialOutOfStock ||
      !item.salableOnline ||
      item.notAllowedAnonymous ||
      item.notAllowedExternalPickupLocation ||
      item.notAllowedBulkyProduct ||
      // @ts-ignore
      (isB2B ? item.notAllowedB2b || item.entryProduct.notAllowedB2b : false)
    );
  });
};

export const enterCheckoutReason = 'Enter checkout';
export const addOrderToCartReason = 'Add order to cart';
export const addListToCartReason = 'Add List to cart';

interface Params {
  storeId?: string;
  slotCode?: string;
  orderID?: string;
  listID?: string;
  wishlistEntries?: Array<WishListFormEntry>;
}

/**
 * Returns an async function that triggers the conflict modal if the cart status returns items that are not sellable
 */
const useCheckCartConflict = (initializeCheckout = false) => {
  const dispatch = useAppDispatch();
  const source = axios.CancelToken.source();
  const { customer } = useCustomer();

  const handleConflict = ({ storeId, orderID, listID, wishlistEntries, slotCode }: Params) =>
    new Promise((resolve, reject) => {
      if (initializeCheckout) {
        initCheckout().then((status) => {
          const conflictingItems = getCartConflictingItems(customer!?.isB2BCustomer, status.cartStatus);
          if (storeId) dispatch(setConflictingModalStoreId(storeId));
          showCartConflictingModal(conflictingItems ?? [], resolve, reject, dispatch, enterCheckoutReason);
        });
      } else if (orderID) {
        getOrderStatus(orderID).then((status) => {
          const conflictingItems = getCartConflictingItems(customer!?.isB2BCustomer, status);
          if (storeId) dispatch(setConflictingModalStoreId(storeId));
          showCartConflictingModal(conflictingItems ?? [], resolve, reject, dispatch, addOrderToCartReason);
        });
      } else if (listID) {
        /* TODO Change what getCartConflictItems uses as input type when OrderStatus and CartStatus also are moved to use the generated API's */
        getListStatus(listID).then((status) => {
          const conflictingItems = getCartConflictingItems(customer!?.isB2BCustomer, status.data);
          if (storeId) dispatch(setConflictingModalStoreId(storeId));
          showCartConflictingModal(conflictingItems ?? [], resolve, reject, dispatch, addListToCartReason);
        });
      } else if (wishlistEntries) {
        /* TODO Change what getCartConflictItems uses as input type when OrderStatus and CartStatus also are moved to use the generated API's */
        getWishListEntriesStatus({ entries: wishlistEntries }, { cancelToken: source.token }).then((status) => {
          const conflictingItems = getCartConflictingItems(customer!?.isB2BCustomer, status.data);
          if (storeId) dispatch(setConflictingModalStoreId(storeId));
          showCartConflictingModal(conflictingItems ?? [], resolve, reject, dispatch, addListToCartReason);
        });
      } else {
        getCartStatus(slotCode, storeId, source.token).then((status) => {
          const conflictingItems = getCartConflictingItems(customer!?.isB2BCustomer, status.data.cartStatus);
          if (storeId) dispatch(setConflictingModalStoreId(storeId));
          showCartConflictingModal(conflictingItems ?? [], resolve, reject, dispatch, status.data.reason);
        });
      }
    });

  return handleConflict;
};

export default useCheckCartConflict;
