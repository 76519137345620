import { useEffect, useMemo, useRef, useState } from 'react';
import Config from '@config';
import useTranslation from 'next-translate/useTranslation';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import Dropdown from '@atoms/Dropdown/Dropdown';
import Spinner from '@molecules/Spinner/Spinner';
import { addToUserWishlist, createUserWishlist } from '@api/interfaces/wishlistApi';
import paths, { LoginQueryParams } from '@constants/paths';
import useCustomRouter from '@hooks/useCustomRouter';
import Icon from '@atoms/Icon/Icon';
import CreateForm from './CreateForm';
import CompleteForm from './CompleteForm';
import AddForm from './AddForm';
import { FormMode } from './types';
import { StyledAddToList, StyledButton, StyledButtonText, StyledErrorText } from './AddToListDropDown.styles';
import useUserAgent from '@hooks/useUserAgent';
import webToAppApi from '@api/web-to-app';
import { WishlistEntryForm } from '@api/generated/storefront';
import useWishlists from '@hooks/useWishlists';
import useCustomer from '@hooks/useCustomer';
import AddToList from '@icons/add-to-list.svg';

interface AddToListCallback {
  option: OptionType;
  productEntries: Array<WishlistEntryForm>;
  tracking?: { parent: string };
}

interface CreateNewListCallback {
  inputValue: string;
}

interface Props {
  productEntries: Array<WishlistEntryForm>;
  align: 'bottom' | 'top';
  forceJustify?: 'center' | 'left' | 'right';
  btnIconSize: number;
  btnText?: string;
  disabled?: boolean;
  queryParamsIfNotLoggedIn?: { from: LoginQueryParams };
  tracking?: { parent: string };
  addToListCallback?: ({ option, productEntries, tracking }: AddToListCallback) => void;
  createNewListCallback?: ({ inputValue }: CreateNewListCallback) => void;
  className?: string;
  forceIconBaseColor?: boolean;
}

const AddToListDropdown = ({
  align,
  forceJustify,
  btnIconSize,
  disabled = false,
  productEntries,
  queryParamsIfNotLoggedIn = { from: LoginQueryParams.MY_LISTS },
  tracking,
  addToListCallback,
  createNewListCallback,
  btnText,
  className,
  forceIconBaseColor = false,
}: Props) => {
  const { t } = useTranslation('addToList');
  const router = useCustomRouter();
  const [showDropdown, setShowDropdown] = useState(false);
  const { customer } = useCustomer();
  const { wishlists, error: wishlistError, isLoading } = useWishlists(customer?.uid);
  const options = useMemo(
    () => (wishlists ? wishlists.map(({ id, name }) => ({ value: id, label: name })) : []),
    [wishlists]
  );
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>('');
  const [formMode, setFormMode] = useState<FormMode>('ADD');
  const [savedToOption, setSavedToOption] = useState({ value: '', label: '' });
  const { isNativeApp } = useUserAgent();
  const timerRef = useRef(0);

  const stopTimer = () => {
    clearTimeout(timerRef.current);
  };
  const startTimer = () => {
    timerRef.current = window.setTimeout(() => {
      setShowDropdown(false);
    }, Config.TIMEOUT.addToListDropdownAutoCloseMs);
  };
  const handleNotLoggedIn = () => {
    if (isNativeApp) {
      webToAppApi.actionRenewAccessToken();
    } else {
      router.push(
        { pathname: router.pathname, query: { ...router.query, ...queryParamsIfNotLoggedIn } },
        {
          pathname: paths.USER_LOGIN,
        },
        { shallow: true }
      );
    }

    if (tracking?.parent) {
      pushGTMEvent({
        event: 'Track',
        category: tracking.parent,
        action: 'save_as_list_redirected_to_login',
        label: '',
        value: 0,
      });
    }
  };

  const handleOpenDropdown = () => {
    if (wishlists) {
      if (wishlists.length > 0) {
        setFormMode('ADD');
      } else {
        setFormMode('NEW');
      }

      if (tracking?.parent) {
        pushGTMEvent({
          event: 'Track',
          category: tracking.parent,
          action: 'save_as_list_widget_shown',
          label: '',
          value: 0,
        });
      }
    }

    if (wishlistError) {
      if (wishlistError.response?.data.customerLoggedIn === 'false') {
        handleNotLoggedIn();
      } else {
        setError(t('addToList->error->onGetLists'));
      }
    }
  };

  useEffect(() => {
    stopTimer();
    setError('');
    if (showDropdown) {
      handleOpenDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  useEffect(() => {
    // Clean up the timer when the component unmounts
    return () => {
      stopTimer();
    };
  }, []);

  const onAddToListHandler = async (option: OptionType) => {
    setIsSaving(true);
    try {
      await addToUserWishlist(option.value, productEntries);
      setSavedToOption(option);
      setFormMode('COMPLETE');
      startTimer(); // start timer when product has been added
      if (addToListCallback) {
        addToListCallback({ option, productEntries });
      }
    } catch (e) {
      setError(t('addToList->error->onSave'));
    } finally {
      setIsSaving(false);
    }
  };

  const onCreateNewListHandler = async (inputValue: string) => {
    setIsSaving(true);
    try {
      const data = await createUserWishlist(inputValue, productEntries);
      if (createNewListCallback) {
        createNewListCallback({ inputValue });
      }
      setSavedToOption({ value: data.id, label: data.name });
      setFormMode('COMPLETE');
    } catch (err) {
      setError(t('addToList->error->onCreate'));
    } finally {
      setIsSaving(false);
    }
  };

  const onMouseLeaveHandler = () => {
    if (formMode === 'COMPLETE') {
      startTimer();
    }
  };

  const onMouseOverHandler = () => {
    if (formMode === 'COMPLETE') {
      stopTimer();
    }
  };

  const changeFormModeHandler = (mode: FormMode) => {
    setFormMode(mode);
  };

  const dropdownCloseHandler = () => {
    startTimer();
  };

  return (
    <StyledAddToList hasText={!!btnText} className={className}>
      <StyledButton
        theme={btnText ? 'secondary' : 'transparent'}
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
        disabled={disabled}
        data-testid="add-to-list-button"
        title={t('addToList->button->title')}
      >
        <Icon
          svg={AddToList}
          size={btnIconSize}
          color={showDropdown && !forceIconBaseColor ? 'primary' : 'lighterBlack'}
        />
        {btnText && <StyledButtonText>{btnText}</StyledButtonText>}
      </StyledButton>

      <Dropdown
        forceJustify={forceJustify}
        align={align}
        isOpen={showDropdown}
        closeHandler={dropdownCloseHandler}
        onMouseOver={onMouseOverHandler}
        onMouseLeave={onMouseLeaveHandler}
      >
        {isLoading && !error ? (
          <Spinner color="black" />
        ) : (
          <>
            {formMode === 'ADD' && (
              <AddForm
                onAddToList={onAddToListHandler}
                changeFormMode={changeFormModeHandler}
                isSaving={isSaving}
                options={options}
              />
            )}
            {formMode === 'NEW' && <CreateForm onCreateNewList={onCreateNewListHandler} isSaving={isSaving} />}
            {formMode === 'COMPLETE' && (
              <CompleteForm
                option={savedToOption}
                onRouteToSpecificList={() => {
                  setShowDropdown(false);
                }}
              />
            )}
          </>
        )}
        {error.length > 0 && (
          <StyledErrorText align="center" color="black" type="detail">
            {error}
          </StyledErrorText>
        )}
      </Dropdown>
    </StyledAddToList>
  );
};

export default AddToListDropdown;
