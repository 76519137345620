import { OrderApi, OrderDataV2Response } from '@api/generated/storefront';
import { AxiosRequestConfig } from 'axios';

const orderApi = new OrderApi(undefined, '');

export const cancelOrder = (orderID: string) => {
  return orderApi.cancelOrderUsingPOST(orderID);
};

export interface OrderDataV2ResponseWithOrderNumber extends OrderDataV2Response {
  orderNumber: string;
}
export const getOrder = async (orderId: string, options?: AxiosRequestConfig) => {
  const response = await orderApi.getFullOrderDataUsingGET(orderId, options);
  return {
    ...response.data,
  } as OrderDataV2ResponseWithOrderNumber;
};

export const getOrderStatus = async (orderID: string) => {
  const response = await orderApi.validateOrderEntriesUsingGET(orderID);
  return response.data;
};

export const getComplaintOrders = async (options?: AxiosRequestConfig) => {
  const response = await orderApi.getOrdersUsingGET(options);
  return response.data;
};
