import {
  openConflictingModal,
  setItemsConflictingModal,
  setRejectConflictingModal,
  setResolveConflictingModal,
} from '@slices/conflictingModalSlice';
import { Dispatch } from 'redux';
import { AxfoodCartEntryStatusViewModel } from '@api/generated/storefront';

const showCartConflictingModal = (
  items: Array<AxfoodCartEntryStatusViewModel>,
  resolve: (value: any) => void,
  reject: (reason: any) => void,
  dispatch: Dispatch,
  reason: string
) => {
  if (items?.length) {
    dispatch(setItemsConflictingModal(items));
    dispatch(openConflictingModal({ open: true, reason }));
    dispatch(setResolveConflictingModal(resolve));
    dispatch(setRejectConflictingModal(reject));
  } else {
    resolve('No conflicting items');
  }
};

export default showCartConflictingModal;
